@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

@layer components {
  .form {
    @apply block w-full px-2 border border-gray-300 focus:ring-0 text-gray-700 h-10 placeholder:text-gray-400  disabled:bg-gray-100 disabled:text-gray-500 text-sm;
  }

  .table {
    @apply min-w-full divide-y border;
  }

  .table-head {
    @apply h-12 px-3.5 text-left truncate text-sm font-medium text-gray-700 bg-gray-50;
  }

  .table-cell {
    @apply h-12 px-3.5 text-sm font-medium text-gray-500 max-w-lg truncate;
  }

  .badge-light {
    @apply inline-flex items-center rounded-sm bg-gray-100 px-2.5 h-8 text-xs font-medium text-gray-800;
  }
  .badge-danger {
    @apply inline-flex items-center rounded-sm bg-red-100 px-2.5 h-8 text-xs font-medium text-red-800;
  }
  .badge-warning {
    @apply inline-flex items-center rounded-sm bg-yellow-100 px-2.5 h-8 text-xs font-medium text-yellow-800;
  }
  .badge-success {
    @apply inline-flex items-center rounded-sm bg-green-100 px-2.5 h-8 text-xs font-medium text-green-800;
  }
  .badge-primary {
    @apply inline-flex items-center rounded-sm bg-blue-100 px-2.5 h-8 text-xs font-medium text-blue-800;
  }
  .icon-xs {
    @apply w-3 h-3;
  }
  .icon-sm {
    @apply w-4 h-4;
  }
  .icon-md {
    @apply w-5 h-5;
  }
  .icon-lg {
    @apply w-10 h-10;
  }
}
